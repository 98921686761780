// src/components/FormSettings/fontsList.js

export const serifFonts = [
	'Merriweather',
	'Playfair Display',
	'Lora',
	'Bitter',
	'Arvo',
	'Slabo 27px',
  ];
  
  export const sansSerifFonts = [
	'Poppins',
	'Roboto',
	'Open Sans',
	'Lato',
	'Montserrat',
	'Nunito',
	'Raleway',
	'Oswald',
	'Ubuntu',
	'Noto Sans',
	'Rubik',
	'PT Sans',
	'Work Sans',
	'Inter',
	'Cabin',
	'Nunito Sans',
	'Mukta',
	'Fira Sans',
	'Josefin Sans',
	'Quicksand',
	'Anton',
	'Bebas Neue',
	'Comfortaa',
	'Karla',
	'Mulish',
	'Righteous',
	'Roboto Condensed',
	'Source Sans Pro',
  ];
  
  // Map each font to its available weights
  export const fontWeights = {
	'Poppins': ['100', '200', '300', '400', '500', '600', '700', '800', '900'],
	'Roboto': ['100', '300', '400', '500', '700', '900'],
	'Open Sans': ['300', '400', '600', '700', '800'],
	'Lato': ['100', '300', '400', '700', '900'],
	'Montserrat': ['100', '200', '300', '400', '500', '600', '700', '800', '900'],
	'Nunito': ['200', '300', '400', '600', '700', '800', '900'],
	'Raleway': ['100', '200', '300', '400', '500', '600', '700', '800', '900'],
	'Oswald': ['200', '300', '400', '500', '600', '700'],
	'Ubuntu': ['300', '400', '500', '700'],
	'Noto Sans': ['400', '700'],
	'Rubik': ['300', '400', '500', '700', '900'],
	'PT Sans': ['400', '700'],
	'Work Sans': ['100', '200', '300', '400', '500', '600', '700', '800', '900'],
	'Inter': ['100', '200', '300', '400', '500', '600', '700', '800', '900'],
	'Cabin': ['400', '500', '600', '700'],
	'Nunito Sans': ['200', '300', '400', '600', '700', '800', '900'],
	'Mukta': ['200', '300', '400', '500', '600', '700', '800'],
	'Fira Sans': ['100', '200', '300', '400', '500', '600', '700', '800', '900'],
	'Josefin Sans': ['100', '200', '300', '400', '600', '700'],
	'Quicksand': ['300', '400', '500', '600', '700'],
	'Anton': ['400'],
	'Bebas Neue': ['400'],
	'Comfortaa': ['300', '400'],
	'Karla': ['200', '300', '400', '500', '600', '700'],
	'Mulish': ['200', '300', '400', '500', '600', '700', '800', '900'],
	'Righteous': ['400'],
	'Roboto Condensed': ['300', '400', '700'],
	'Source Sans Pro': ['200', '300', '400', '600', '700', '900'],
	// Serif fonts
	'Merriweather': ['300', '400', '700', '900'],
	'Playfair Display': ['400', '500', '600', '700', '800', '900'],
	'Lora': ['400', '500', '600', '700'],
	'Bitter': ['400', '500', '600', '700'],
	'Arvo': ['400', '700'],
	'Slabo 27px': ['400'],
  };
  